export default {
  popup__common: {
    position: "relative",
    "svg.close__btn": {
      position: "absolute",
      top: "5px",
      right: "10px",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    ".popup__common__title": {
      padding: "1rem",
      fontSize: "1.1rem",
      fontWeight: 700,
    }
  },
  product__info : {
    padding: "1rem",
    '.product__name' : {
      fontWeight: 700,
    }
  },
  product__info__container : {
    display: 'flex',
    marginTop: '0.5rem',
    marginLeft: '-0.2rem',
    marginRight: '-0.2rem',
    flexWrap: 'wrap',
  },

  product__info__img : {
    maxWidth: '127px',
    paddingLeft: '0.2rem',
    paddingRight: '0.2rem'
  },

  product__info__detail : {
    flex: '1 1 auto',
    paddingLeft: '0.2rem',
    paddingRight: '0.2rem',
    display: 'flex',
    flexDirection : 'column',
  },

  product__info__detail__item : {
    display: 'flex',
    flexDirection: 'row',
    flexWrap : 'wrap',
    '.product__info__detail__item_1' : {
      flex : '1 0 30%',
      maxWidth: '30%',
      paddingLeft: '0.2rem',
      paddingRight: '0.2rem',
      fontWeight : 700
    },
    '.product__info__detail__item_2' : {
      flex : '1 0 70%',
      maxWidth: '70%',
      paddingLeft: '0.2rem',
      paddingRight: '0.2rem'
    }
  },

  product__info__detail__total : {
    flex : '1 0 auto',
    //marginTop : '0.5rem',
    borderBottom: '1px solid black',
    //alignContent: 'flex-end'
  },

  contiune__button: {
    backgroundColor: "transparent",
    borderColor: "#080808",
    color: 'black',
    fontWeight: 600,
    borderRadius: "2rem",
    padding: "0.6rem 1rem",
    fontSize: '0.8rem',
    marginLeft : '0.5rem',
    marginRight : '0.5rem'
  },

  contiune__button__p: {
    backgroundColor: "#43B02A",
    borderColor: "#43B02A",
    fontWeight: 600,
    borderRadius: "2rem",
    padding: "0.6rem 1rem",
    fontSize: '0.8rem',
    marginLeft : '0.5rem',
    marginRight : '0.5rem'
  },

};