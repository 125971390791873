/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./popupErrorStyle";
import { MdClose } from "react-icons/md";

export function PopupError({ changeOptionItems }) {
    return (
      <div className="popup__error popup__common" css={[style.popup__common]}>
        <div className="popup__common__title">
          <div style={{ maxWidth: "95%" }}>
            An error has occurred, please check error messages to continue
          </div>
        </div>
        <MdClose
          className="close__btn"
          onClick={() => {
            changeOptionItems("closepopup");
          }}
        />
      </div>
    );
  }