//import variables from "../../../../cssInJs/variables";
//import { mq } from "../../../../cssInJs";

export default {
  popup__common: {
    position: "relative",
    "svg.close__btn": {
      position: "absolute",
      top: "5px",
      right: "10px",
      fontSize: "1.5rem",
      cursor: "pointer",
    },
    ".popup__common__title": {
      padding: "1rem",
      fontSize: "1.1rem",
      fontWeight: 700,
    },
  }
};