/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./orderItemStyle";
import env from "../../../env";
import { Button, ErrorMessage } from "../../../components";
import utils from "../../../utils";
import { CartSummary } from "../cartSummary/summary";

export function OrderItems({ cart, form, moveNext, post, remove }) {
  //const model = form.model;
  const items = form.findControl("items").controls;
  //const render = (name, parent = null) => form.renderControl(name, parent);

  function itemAmount(item, orderItem) {
    const val = item.getValue();
    return (val.quantity * (orderItem.unitPrice * 100)) / 100;
  }

  const next = (e) => {
    form.validateForm(e, () => {
      moveNext();
    });
  };

  function ItemVariantSummary({ orderItem }) {
    //if (!orderItem.variantOptionsSerialised) return null;
    //const options = JSON.parse(orderItem.variantOptionsSerialised);
    const options = orderItem.optionItems;
    return (
      <div style={{marginTop : '1rem'}}>
        {
          options.map((x, index) => (
            <div key = {index} css={style.optionCon}>
              <div css={style.optionName}>{x.name}:</div>
              <div css={style.optionValue}>{x.value}</div>
            </div>
          ))
          // .reduce(
          //   (acc, x) =>
          //     acc === null ? (
          //       x
          //     ) : (
          //       <React.Fragment>
          //         {acc}, {x}
          //       </React.Fragment>
          //     ),
          //   null
          // )
        }
      </div>
    );
  }

  const orderItems = cart.orderItems.filter((x) => x.orderItemType === 0);
  const taxLines = cart.orderItems.filter((x) => x.orderItemType === 1);

  return (
    <div>
      <div className="row">
        <div className="col-md-9">
          <form css={style.items}>
            {orderItems.map((orderItem, index) => (
              <div key={orderItem.orderItemId} css={style.item}>
                <div className="row">
                  <div className="col-sm-2">
                    <img
                      src={env.resourceBase + orderItem.thumbnailUrl}
                      alt="immmm"
                    />
                  </div>

                  <div className="col-sm-7">
                    <b
                      dangerouslySetInnerHTML={{ __html: orderItem.itemName }}
                    ></b>
                    <ItemVariantSummary orderItem={orderItem} />
                  </div>

                  {/* <div className = "col-sm-2">
                Qty: {render('quantity', items[index])}
              </div> */}

                  <div className="col-sm-3" css={style.remove}>
                    <b>
                      {utils.money.formatMoney(
                        itemAmount(items[index], orderItem)
                      )}
                    </b>
                    <Button
                      btnStyle="outline-dark"
                      className="btn-sm"
                      onClick={(e) => remove(e, index)}
                      status={form.status}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            {taxLines.map((orderItem, index) => (
              <div key={orderItem.orderItemId} css={style.item}>
                <div css={style.product}>Tax: {orderItem.itemName}</div>
                <div css={style.details}>
                  <div css={style.qty}></div>
                  <div css={style.amount}>
                    <b>{utils.money.formatMoney(orderItem.unitPrice)}</b>
                  </div>
                  <div css={style.remove}></div>
                </div>
              </div>
            ))}
          </form>
        </div>
        <div className="col-md-3">
          <CartSummary cart={cart} />
        </div>
        <div className="col-md-9 form__actions" style={{ textAlign: "center" }} css={style.b_container}>
          <Button onClick={next} status={form.status}>
            Next
          </Button>
          <ErrorMessage
            errors={form.errors}
            summaryMessage="Please review the errors."
          />
        </div>
      </div>
    </div>
  );
}
