/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./style";
import { MdClose, MdCheck } from "react-icons/md";
import env from "../../../../env";
import { useState } from "react";

export function DesignReview({ changeOptionItems, option }) {
  const [checkDesign, setCheckDesign] = useState(false);
  const [showingImg, setShowingImg] = useState(option.firstFile);

  function changeOption() {
    setCheckDesign(!checkDesign);
  }

  function changeImg(imgPath) {
    setShowingImg(imgPath);
  }

  return (
    <div
      className="popup__common"
      css={[style.popup__common, style.design__review]}
    >
      <div className="popup__common__title">
        {/* <div style={{ maxWidth: "95%" }}> Design Review </div> */}
      </div>
      <MdClose
        className="close__btn"
        onClick={() => {
          changeOptionItems("closepopup");
        }}
      />

      <div
        className="design__review__co"
        css={style.design__review__co}
        style={{ padding: "0rem 1rem 1rem" }}
      >
        <div className="design__review__img">
          <div style={{ maxHeight: "400px", overflow: "auto" }}>
            {showingImg && showingImg !== "" ? (
              <img
                src={`${env.apiBase}/media/uploadselected/${showingImg}`}
                width="100%"
                alt="showing_img"
              />
            ) : (
              <div> No image</div>
            )}
          </div>

          <div className="buttton3__container" css={style.buttton3__container}>
            <button
              className={`btn btn-primary${
                showingImg === option.firstFile && " selected"
              }`}
              css={style.buttton3}
              onClick={() => {
                changeImg(option.firstFile);
              }}
            >
              Front
            </button>

            <button
              className={`btn btn-primary${
                showingImg === option.secondFile && " selected"
              }`}
              css={style.buttton3}
              onClick={() => {
                changeImg(option.secondFile);
              }}
            >
              Back
            </button>
          </div>
        </div>
        <div className="design__review__contetns">
          <div
            className="design__review__title"
            css={style.design__review__title}
          >
            {" "}
            Design Review{" "}
          </div>
          <div
            className="design__review__des top__title"
            css={style.design__review__des}
          >
            All done? Let's double check :
          </div>
          <div
            className="design__review__des check__title"
            css={style.design__review__des}
          >
            <MdCheck />
            Text : Is it clear and legible?
          </div>
          <div
            className="design__review__des check__title"
            css={style.design__review__des}
          >
            <MdCheck />
            Margin : Is everything within the space?
          </div>
          <div
            className="design__review__des check__title"
            css={style.design__review__des}
          >
            <MdCheck />
            Info : Everything accurate? Spelled correctly?
          </div>
          <div
            className="design__review__des check__title"
            css={style.design__review__des}
          >
            <MdCheck />
            Images: Is everything clear(no blurriness)?
          </div>

          <div style={{ marginTop: "1rem" }}>
            <button
              className={`btn btn-primary`}
              css={style.buttton1}
              onClick={() => {
                changeOptionItems("changedesign");
              }}
            >
              Change my design
            </button>
          </div>

          <hr className="design__review__hr" css={style.design__review__hr} />

          <div
            className="design__review__form__check form-group form-check"
            css={style.design__review__form__check}
          >
            <input
              id="design__review__form__check__input"
              name="design__review__form__check__input"
              type="checkbox"
              //value={value || ""}
              checked={checkDesign || false}
              className={"form-check-input"}
              onChange={changeOption}
            />
            <label
              htmlFor="design__review__form__check__input"
              className="form-check-label"
            >
              I've reviewed my design and I approve it.
            </label>
          </div>

          <div style={{ marginTop: "1rem" }}>
            <button
              className={`btn btn-primary`}
              css={style.buttton2}
              disabled={!checkDesign}
              onClick={(e) => {
                changeOptionItems("callAddToCart", { e: e });
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
