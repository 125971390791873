/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from 'react';
import style from "./style";
import { MdClose } from "react-icons/md";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import env from "../../../../env";

registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

export function SelectAnImage({
  changeOptionItems,
  filePondRef,
  option,
  title,
  functionName,
  type,
}) {
  const [hideSkipButton, setHideSkipButton] = React.useState( type === 'firstFile' ? true : false );
  return (
    <div className="popup__common" css={[style.popup__common]}>
      <div className="popup__common__title">
        <div style={{ maxWidth: "95%" }}> {title} </div>
      </div>
      <MdClose
        className="close__btn"
        onClick={() => {
          if(!hideSkipButton){
            changeOptionItems("finishuploadfiles2");
          }
          else{
            changeOptionItems("closepopup");
          }

        }}
      />
      <div style={{ padding: "0rem 1rem 1rem" }}>
        {" "}
        Please make sure that your image resolution is at least 300 dpi per
        inch and uses an accepted format:
      </div>

      <div style={{ padding: "0rem 1rem 0rem" }}>
        <FilePond
          //files={option.files}
          allowMultiple={false}
          maxFiles={1}
          name="files"
          maxFileSize={"10MB"}
          allowDrop={false}
          ref={filePondRef}
          allowReorder={true}
          labelIdle='<span class="filepond--label-action"> Upload an image </span>'
          acceptedFileTypes={["image/*", 'application/pdf']}
          onupdatefiles={() => {
            //changeOptionItems("uploadfiles", { filesvvv: files[0]});
            setHideSkipButton(true);
          }}
          // onprocessfilestart={() => {
          
          // }}
          onprocessfiles={() => {
            setTimeout(() => {
              changeOptionItems(functionName);
            }, 500);
          }}
          server={{
            process: {
              url: env.apiBase + "/api/cart/uploadselectedimage",
              // ondata: (formData) => {
              //   formData.append("contactEmail", "vavvvveeeeee");
              //   return formData;
              // },
              // onerror: (response) => {
              //   console.log('onerror', response);
              // },
              onload: (response) => {
                changeOptionItems("uploadfiles", {
                  file: response,
                  type: type,
                });
              },
            },
            fetch: null,
            revert: null,
          }}
        />

        {/* {!hideSkipButton && (
          <div
            style={{
              paddingBottom: "1rem",
              marginTop: "-1.5rem",
              textAlign: "center",
            }}
          >
            <button
              style={{ borderRadius: "20px", fontSize: "0.8rem" }}
              className="btn btn-primary"
              onClick={() => {
                changeOptionItems("finishuploadfiles2");
              }}
            >
              Skip uploading an image
            </button>
          </div>
        )} */}

      </div>
    </div>
  );
}