/** @jsx jsx */
import { jsx } from "@emotion/core";
//import { useState, useEffect } from "react";
//import env from "../../../env";
//import { SiteLink } from "../../../components"; //useRouter
//import { useHistory } from "react-router-dom";
//import { useForm } from "../../../form";
//import { fb, validators } from '../../../form';
//import { Button, ErrorMessage } from "../../../components";
//import { usePost } from "../../../components";
//import utils from "../../../utils";
//import style from "./productViewStyle";
import { MdKeyboardArrowDown } from "react-icons/md";
//import { useStore } from "../../../store";
//import { MdKeyboardArrowDown } from "react-icons/md";
//import {ProductAttributes} from './productAttributes';

export function AddOptions2({ product, option, changeOptionItems }) {
  const s = getListStyle();
  const options = option.options.length > 0 ? option.options.filter((x) => x.type === 1) : [];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginLeft: "-0.5rem",
        marginRight: "-0.5rem",
      }}
    >
      {options.map((x, index) => {
        return (
          <div
            key={index}
            className={`select__option__con ${x.open ? "open" : "close"}`}
            css={s.select__option__con}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                changeOptionItems("open", { id: x.id });
              }}
            >
              <div
                className={`select__option ${x.open ? "open" : "close"}`}
                css={s.select__option}
              >
                <div className="title__value">
                  {x.open ? x.originTitle : x.value}
                </div>
              </div>
              <MdKeyboardArrowDown className={`${x.open ? "open" : "close"}`} />
            </div>

            <div
              className={`select__option__list ${x.open ? "open" : "close"}`}
              css={s.select__option__list}
            >
              <div
                className="select__option__list__inner"
                css={s.select__option__list__inner}
              >
                {x.options.map((y, cIndex) => {
                  return (
                    <div
                      className={`select__option__list__item ${
                        y.value === x.value ? "selected" : "unselected"
                      }`}
                      css={s.select__option__list__item}
                      key={cIndex}
                      onClick={() => {
                        changeOptionItems("changevalue", {
                          id: x.id,
                          value: y.value,
                          quantity : y.quantity,
                          price : y.price
                        });
                      }}
                    >
                      {y.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function getListStyle() {
  return {
    select__option__con: {
      position: "relative",
      flex: "1 0 50%",
      maxWidth: "50%",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
      svg: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "0.5rem",
        fontSize: "1.8rem",
        //cursor: "pointer",
        transition: "all 0.2s",
        "&.open": {
          transform: "translateY(-50%) rotate(180deg)",
        },
      },
    },

    select__option: {
      border: "1px solid #ebebeb",
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      padding: "0.5rem 1rem",
      borderRadius: "0.5rem",
      fontSize: "1rem",
      fontWeight: 300,
      //maxHeight : '47px',
      ".title__value": {
        WebkitLineClamp: "1",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
      },
    },

    select__option__list: {
      position: "absolute",
      width: "95%",
      zIndex: 1,
      maxHeight: 0,
      overflow: "hidden",
      backgroundColor: "white",
      transition: "all 0.3s",
      //border: "1px solid black",
      "&.open": {
        maxHeight: "1000px",
        transition: "all 1.5s",
      },
    },
    select__option__list__inner: {
      border: "2px solid black",
      borderRadius: "0.5rem",
    },
    select__option__list__item: {
      padding: "0.5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "grey",
        color: "white",
      },
      "&.selected": {
        backgroundColor: "grey",
        color: "white",
      },
    },
  };
}
