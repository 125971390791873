//import { css } from "@emotion/core";
//import { variables, mq } from "../../../cssInJs";

export default {
  b_container: {
    'Button': {
      backgroundColor: "#43B02A",
      borderColor: "#43B02A",
      color: "white",
    },
  },
};
