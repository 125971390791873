import { mq } from "../../../cssInJs";

export default {
  pay: {
    'h3': {
      marginTop: 0
    }
  },
  stripeWrapper: {
    margin: '1rem 0 1rem',
    maxWidth: 500,
    padding: '1rem',
    //backgroundColor: '#999999'
  },
  formActions: {
    marginTop: '2rem',
    'Button' : {
      backgroundColor: '#43B02A',
      borderColor : '#43B02A',
      color: 'white'
    }
  },
  paymentInfoText : mq({
    fontSize: ['0.6rem', '0.5rem'],
    marginTop: '1rem',
    '.contact-link' : {
      //color: 'white'
    }
  })
}