import utils from "../utils";
import env from '../env';
import { isMin } from '../lib/css-emotion'

export const isMobile = () => {
  // isMin.md() ==> min-width: 768px
  return !isMin.md()
}

export function resourcePath(url){
  if (!url) return null;
  if (url && url.indexOf('/assets/') === 0) return url;
  return utils.url.isExternalLink(url)? url : env.resourceBase + url;
}

export function fullUrl(url){
  return utils.url.isExternalLink(url)? url : env.baseOrigin + url;
}


export const isNZ = env.locale === 'nz'

export function getRandomText() {
  let result = "";
  const length = 8;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}